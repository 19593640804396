module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":1600,"sizeByPixelDensity":true,"quality":80,"withWebp":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"closeTimeoutMS":200,"style":{"overlay":{"zIndex":3,"backgroundColor":"rgba(0,0,0,0.7)"},"content":{"backgroundColor":"#282B3A","top":0,"width":"300px","right":0,"bottom":0,"border":"none","borderRadius":"0","padding":0}}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141527193-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://citra.co"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
